/* ==========================================================================
   Variables
   ========================================================================== */
/* ==========================================================================
   Helpers
   ========================================================================== */
.clear {
  margin: 0px;
  padding: 0px;
  font-size: 0px;
  line-height: 0px;
  height: 1px;
  clear: both;
  float: none;
}
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  content: "";
  display: table;
}
.clearfix:after {
  clear: both;
}
/* ==========================================================================
   Responsive settings
   ========================================================================== */
@-ms-viewport {
  width: device-width;
  initial-scale: 1;
}
@-o-viewport {
  width: device-width;
  initial-scale: 1;
}
@viewport {
  width: device-width;
  initial-scale: 1;
}
/* ==========================================================================
   Base
   ========================================================================== */
* {
  box-sizing: border-box;
  outline: none;
}
a {
  text-decoration: none;
  color: white;
}
p {
  margin: 25px 0px;
  padding: 0px;
}
hr {
  display: block;
  height: 1px;
  border: 0 none;
  border-top: 1px solid #ccc;
  margin: 0px;
  padding: 0px;
}
h1 {
  margin: 0;
  font-weight: 400;
  font-size: 38px;
  line-height: 46px;
  text-transform: uppercase;
}
input,
textarea {
  -webkit-appearance: none;
  border-radius: 0;
}
button {
  background: none;
  border: none;
  border-spacing: 0;
  border-radius: 0;
  font-size: inherit;
  font-weight: normal;
  line-height: normal;
  margin: 0;
  padding: 0;
  text-align: left;
  text-decoration: none;
  text-indent: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
}
button::-moz-focus-inner {
  border: 0;
  padding: 0;
}
/* ==========================================================================
   Icons
   ========================================================================== */
.icon {
  display: inline-block;
}
.icon-arrow-top,
.icon-arrow-bottom,
.icon-arrow-left,
.icon-arrow-right,
.icon-close,
.icon-search,
.icon-top {
  fill: none;
  stroke: #fff;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 2;
}
.icon-arrow-top {
  width: 30px;
  height: 16px;
}
.icon-play {
  fill: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30%;
  height: 30%;
  transform: translate(-50%, -50%);
}
.icon-menu {
  fill: #fff;
  width: 30px;
  height: 30px;
}
.icon-logo {
  fill: #fff;
  width: 100%;
  height: 100%;
}
.icon-logo2 {
  fill: #fff;
}
.icon-logo-ct {
  fill: #fff;
  width: 100px;
  height: 26px;
}
.icon-logo-gear {
  fill: #fff;
  width: 40px;
  height: 40px;
}
.icon-search {
  width: 25px;
  height: 25px;
}
.icon-top {
  width: 100%;
  height: 100%;
}
/* ==========================================================================
   Links
   ========================================================================== */
.link {
  background: none;
  border: none;
  border-spacing: 0;
  font-size: inherit;
  font-weight: normal;
  line-height: normal;
  margin: 0;
  padding: 0;
  text-align: left;
  text-decoration: none;
  text-indent: 0;
}
/* ==========================================================================
   Skeleton
   ========================================================================== */
html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}
body {
  background-color: #262626;
  font-family: 'Kelson Sans RU', Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #000;
  color: #fff;
}
.center {
  width: 767px;
  max-width: 767px;
  margin: 0 auto;
  position: relative;
  height: 100%;
  width: 100%;
}
.wrapper {
  min-height: 100%;
}
.container {
  padding-top: 60px;
}
/* ==========================================================================
   Page property
   ========================================================================== */
.locked {
  overflow: hidden;
}
.main-page .container {
  padding-top: 0;
}
.main-page .header {
  background: transparent;
}
.main-page .header-logo,
.main-page .header-up,
.main-page .header-title {
  display: none;
}
/* ==========================================================================
   Header
   ========================================================================== */
.header {
  width: 100%;
  height: 60px;
  background: #404040;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}
.header .logo {
  display: block;
  padding: 10px 15px;
  font-size: 0;
  line-height: 0;
}
.header-line {
  position: relative;
  z-index: 1000;
}
.header-line.active .header-logo,
.header-line.active .header-up,
.header-line.active .header-title {
  display: none;
}
.header-line.active .header-burger {
  background: #262626;
}
.header-line .center {
  display: table;
  width: 100%;
}
.header-logo,
.header-up,
.header-burger {
  width: 70px;
  height: 60px;
  display: table-cell;
  vertical-align: top;
}
.header-logo,
.header-up {
  text-align: left;
}
.header-burger {
  text-align: right;
}
.up,
.burger {
  display: inline-block;
  font-size: 0;
  line-height: 0;
  height: 60px;
}
.up {
  padding: 20px;
}
.burger {
  padding: 15px 20px;
}
.burger-lines {
  position: relative;
  display: inline-block;
  text-decoration: none;
  height: 20px;
  width: 30px;
}
.burger-line {
  display: block;
  background: #fff;
  width: 30px;
  height: 3px;
  border-radius: 100px;
  position: absolute;
  left: 0;
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.burger-line-1 {
  top: 0;
}
.burger-line-2 {
  top: 8px;
}
.burger-line-3 {
  top: 16px;
}
.burger.active .burger-line-1 {
  top: 8px;
  -moz-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.burger.active .burger-line-2 {
  opacity: 0;
}
.burger.active .burger-line-3 {
  top: 8px;
  -moz-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.header-title {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  max-width: 50%;
}
.header-title span {
  margin: 7px 5px 3px;
  max-height: 44px;
  overflow: hidden;
  font-size: 20px;
  line-height: 22px;
  text-transform: uppercase;
  font-weight: 700;
  display: block;
}
.header-panel {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #262626;
  width: 100%;
  height: 100%;
  padding-top: 60px;
  overflow: auto;
  z-index: 999;
}
.header-panel.active {
  display: block;
}
.header-panel .center {
  padding: 0 20px 20px;
}
.header-search {
  margin-bottom: 20px;
}
.search {
  position: relative;
}
.search .search-input {
  width: 100%;
  background: transparent;
  border: 3px solid #fff;
  border-radius: 5px;
  height: 50px;
  padding: 10px 60px 10px 20px;
  font-size: 16px;
  text-transform: uppercase;
}
.search .search-button {
  width: 26px;
  height: 26px;
  position: absolute;
  margin: 12px 20px;
  right: 0;
  top: 0;
}
.menu .menu-list {
  margin: 0;
  padding: 0;
  list-style: none;
}
.menu .menu-item {
  margin-bottom: 20px;
}
.menu .menu-item.active .menu-arrow {
  display: block;
}
.menu .menu-link {
  color: #fff;
  position: relative;
  line-height: normal;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}
.menu .menu-icon {
  width: 40px;
}
.menu .menu-arrow {
  width: 12px;
  height: 26px;
  display: none;
}
.menu .menu-text {
  font-size: 24px;
  line-height: 30px;
  text-transform: uppercase;
  padding-top: 5px;
}
.menu .menu-item--logo {
  height: 70px;
}
.menu .menu-item--logo a {
  display: block;
  height: 100%;
  padding: 5px;
  padding-left: 40px;
}
.menu .menu-item--logo img {
  height: 100%;
}
/* ==========================================================================
   Common
   ========================================================================== */
.title {
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  background: #404040;
  padding: 10px;
}
.list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.list .item-image {
  display: block;
  overflow: hidden;
  position: relative;
  font-size: 0;
  line-height: 0;
}
.list img {
  max-width: 100%;
  width: 100%;
  height: auto;
}
.list .item-content {
  padding: 10px;
}
.list .item-title {
  color: #fff;
}
.action-more {
  position: relative;
  text-align: center;
  display: block;
  width: 100%;
  padding: 10px 50px;
}
.action-more .action-icon {
  position: absolute;
  left: 0;
  top: 0;
  margin: 0;
  padding: 0;
  padding: 12px 10px;
  overflow: hidden;
}
.action-more .arrow-more {
  width: 26px;
  height: 12px;
}
.action-more .action-text {
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 700;
}
.promo-text {
  padding: 20px;
  text-align: justify;
  font-size: 16px;
  font-weight: 300;
}
.news .list .item,
.detail-news .list .item {
  width: 50%;
  background: #404040;
}
.news .list .item-image,
.detail-news .list .item-image {
  height: 130px;
}
.news .list img,
.detail-news .list img {
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.news .list .item-title,
.detail-news .list .item-title {
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  display: block;
}
.projects .list .item {
  width: 50%;
  background: #404040;
}
.projects .list .item-image {
  height: 130px;
}
.projects .list img {
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.projects .list .item-title {
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 10px;
  display: block;
}
.projects .list .item-text {
  font-size: 14px;
  font-weight: 400;
}
.presenters .list .item {
  width: 33.333%;
  height: 170px;
}
.presenters .list .item-image {
  width: 100%;
  height: 100%;
  display: block;
}
.presenters .list img {
  height: 100%;
  width: 100%;
  max-width: 100%;
  object-fit: cover;
  object-position: center;
}
.presenters .list .item-title {
  display: none;
}
.companions .list {
  font-size: 0;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 1rem 0;
}
.companions .list .item {
  flex: 0 0 auto;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  width: 20%;
}
.companions .list .item-image {
  max-width: 100%;
  height: auto;
}
.banners {
  margin-bottom: 20px;
}
.banners .list {
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.banners .list .item {
  display: inline-block;
  vertical-align: top;
  width: 50%;
  background: #404040;
}
.banners .list img {
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.partners {
  padding: 20px;
  font-size: 16px;
  font-weight: 100;
}
.partners ul {
  margin: 20px 0;
  padding-left: 15px;
}
.partners ul li {
  text-transform: uppercase;
  font-weight: 400;
  margin-bottom: 5px;
  font-size: 14px;
}
.partners a {
  color: #fff;
}
.external-partners {
  margin-bottom: 20px;
}
.external-partners .list {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.external-partners .item {
  display: inline-block;
  vertical-align: top;
  width: 20%;
  padding: 10px;
}
.external-partners img {
  max-width: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.contacts {
  padding: 20px;
}
.contacts .contacts-item {
  margin-bottom: 40px;
}
.contacts .item-text {
  margin-bottom: 15px;
  font-weight: 100;
  font-size: 16px;
}
.contacts .item-address {
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 2px;
}
.contacts a {
  color: #fff;
}
.contacts .item-phone {
  text-transform: uppercase;
  font-weight: 700;
}
.program {
  padding-top: 70px;
}
.program-timeline {
  position: fixed;
  top: 60px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 100%;
  max-width: 767px;
  z-index: 100;
}
.timeline {
  background-color: #262626;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  text-align: center;
  font-size: 0;
  height: 70px;
}
.timeline .date {
  width: 14.28571429%;
  padding: 10px 5px;
  background: #262626;
  color: #fff;
}
.timeline .date.active {
  background: #404040;
}
.timeline .date-weekday {
  font-size: 26px;
  font-weight: 700;
  text-transform: uppercase;
}
.timeline .date-number {
  font-size: 11px;
}
.program-item {
  background: #404040;
  padding: 20px 20px 10px;
  display: table;
  width: 100%;
}
.program-item .item-time,
.program-item .item-content {
  display: table-cell;
  vertical-align: top;
}
.program-item .item-time {
  font-size: 24px;
  font-weight: 700;
  padding-right: 20px;
  width: 100px;
}
.program-item .item-title {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 10px;
  display: block;
  color: #fff;
}
.program-item .item-description {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 10px;
}
.program-item .item-text {
  font-weight: 100;
  font-size: 16px;
}
.program-item:last-child {
  padding-bottom: 30px;
}
.search-detail {
  padding: 30px 20px 0px;
}
.search-box {
  margin-bottom: 30px;
}
.search-box:last-child {
  margin-bottom: 0;
}
.search-result {
  margin-bottom: 30px;
}
.result-list .result-item {
  margin-bottom: 30px;
}
.result-list .item-title {
  color: #fff;
  text-transform: uppercase;
  margin-bottom: 10px;
  display: block;
  font-size: 20px;
}
.result-list span {
  border-left: 1px solid #fff;
  display: inline;
  vertical-align: middle;
  padding-left: 10px;
  margin-left: 10px;
}
.result-list .item-text {
  font-size: 18px;
  font-weight: 100;
}
.about-video {
  position: relative;
  cursor: pointer;
}
.about-video.active .about-video-icon,
.about-video.active .about-video-image {
  opacity: 0;
  display: none;
  z-index: -1;
}
.about-video.active .about-video-frame {
  opacity: 1;
  position: relative;
  z-index: 1;
}
.about-video-icon {
  opacity: 1;
}
.about-video-image {
  font-size: 0;
  margin: 0;
  padding: 0;
  opacity: 1;
  z-index: 1;
}
.about-video-image iframe {
  height: 100%;
}
.about-video-image img {
  max-width: 100%;
  width: 100%;
  height: auto;
}
.about-video-frame {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  min-height: 360px;
}
.about-video-frame .video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.about-video-frame img {
  max-width: 100%;
  height: auto;
}
.about-video-frame iframe {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border: 0;
}
.about-description {
  padding: 10px;
  font-size: 12px;
  font-weight: 100;
  text-transform: uppercase;
}
.about-description span {
  display: inline-block;
  border-left: 1px solid #fff;
  padding-left: 10px;
  margin-left: 10px;
}
.about-post .post-text {
  height: 100px;
  overflow: hidden;
  display: block;
  position: relative;
  padding: 10px;
  font-weight: 100;
  font-size: 16px;
}
.about-post .post-text:after {
  content: '';
  display: block;
  width: 100%;
  height: 50px;
  position: absolute;
  bottom: 0;
  left: 0;
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, #262626 100%);
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, #262626 100%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, #262626 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#262626', GradientType=0);
}
.about-post .post-actions {
  padding: 0 20px;
  margin: 10px 0;
  text-align: right;
}
.about-post .arrow-post {
  width: 20px;
  height: 10px;
  transition: all 0.3s ease-in-out;
}
.about-post p {
  margin-top: 0;
  margin-bottom: 10px;
}
.about-post p:last-child {
  margin-bottom: 0;
}
.about-post.active .post-text {
  height: auto;
}
.about-post.active .post-text:after {
  display: none;
}
.about-post.active .arrow-post {
  transform: rotate(-180deg);
}
.detail-data .data {
  padding: 20px;
  font-weight: 100;
  font-size: 16px;
  *zoom: 1;
}
.detail-data .data:before,
.detail-data .data:after {
  content: "";
  display: table;
}
.detail-data .data:after {
  clear: both;
}
.detail-data .data-image {
  float: left;
  margin-right: 20px;
  margin-bottom: 10px;
  max-width: 100%;
  width: 50%;
  height: auto;
}
.detail-data p {
  margin-top: 0;
  margin-bottom: 10px;
}
.detail-data p:last-child {
  margin-bottom: 0;
}
.media .list .item {
  width: 50%;
  background: #404040;
  position: relative;
}
.media .list .item-image {
  height: 130px;
}
.media .list img {
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.media .list .media-play {
  width: 45%;
  height: 45%;
}
.not-found {
  padding: 20px;
}
.not-found-title {
  font-size: 28px;
  font-weight: 100;
  text-align: center;
  margin-bottom: 20px;
}
.not-found-text {
  font-size: 16px;
  font-weight: 100;
}
/* ==========================================================================
   Swiper
   ========================================================================== */
.carousel {
  position: relative;
}
.carousel-button {
  background: none;
  top: 50%;
  margin-top: -13px;
  width: 12px;
  height: 26px;
  z-index: 3;
}
.carousel-button .icon {
  width: 12px;
  height: 26px;
  stroke: #fff;
}
.swiper-button-prev {
  left: 10px;
}
.swiper-button-next {
  right: 10px;
}
.swiper-pagination {
  top: 0;
  padding: 20px;
}
.swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  background: rgba(255, 255, 255, 0.5);
}
.swiper-pagination-bullet-active {
  background: #fff;
}
.promo-carousel .item-header {
  position: relative;
  padding-bottom: 56.25%;
  margin: 0 40px;
}
.promo-carousel .item-logo {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  transform: translate(-50%, -50%);
  width: 50%;
  height: 60%;
  opacity: 0.5;
}
.promo-carousel .item-image {
  position: relative;
  font-size: 0;
  line-height: 0;
  height: 250px;
}
.promo-carousel .item-image:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
}
.promo-carousel img {
  max-width: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.promo-carousel .item-content {
  padding: 20px;
}
.promo-carousel .item-text {
  text-align: justify;
  font-size: 16px;
  font-weight: 300;
}
.promo-carousel iframe,
.promo-carousel video {
  border: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.promo-carousel .carousel-pagination {
  height: auto;
  bottom: auto;
  z-index: 100;
}
.gallery-carousel .item-header {
  position: relative;
}
.gallery-carousel .item-image {
  position: relative;
  font-size: 0;
  line-height: 0;
  overflow: hidden;
}
.gallery-carousel img {
  max-width: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.air-carousel .carousel-container {
  margin: 0 35px;
}
.air-carousel .carousel-item {
  padding: 10px 5px;
  color: #fff;
}
.air-carousel .item-time {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 5px;
}
.air-carousel .item-title {
  font-size: 12px;
  overflow: hidden;
  position: relative;
}
.air-carousel .item-title:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 20px;
  height: 100%;
  display: block;
  background: -moz-linear-gradient(left, rgba(0, 0, 0, 0) 0%, rgba(38, 38, 38, 0.65) 100%);
  background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0) 0%, rgba(38, 38, 38, 0.65) 100%);
  background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(38, 38, 38, 0.65) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#a6262626', GradientType=1);
}
/* ==========================================================================
   Fancybox
   ========================================================================== */
.fancybox-overlay {
  background-color: #404040;
  background-color: rgba(0, 0, 0, 0.8);
  box-shadow: none;
}
.fancybox-opened .fancybox-skin {
  box-shadow: none;
  height: inherit;
}
.fancybox-wrap,
.fancybox-desktop,
.fancybox-type-image,
.fancybox-opened {
  padding: 0;
  margin: 0;
  height: auto !important;
}
.fancybox-skin {
  border-radius: 0px;
  background: transparent;
  box-shadow: none;
}
.fancybox-inner {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: auto !important;
}
.fancybox-inner img {
  max-width: 100%;
  width: 100%;
  height: auto;
}
.fancybox-inner iframe {
  height: auto;
}
.fancybox-close {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(../images/close.png);
  width: 20px;
  height: 20px;
  top: -50px;
  right: 0;
  margin: 10px;
}
.fancybox-nav {
  width: 25px;
  height: 40px;
  top: 50%;
  margin-top: -20px;
}
.fancybox-nav span {
  width: 26px;
  height: 26px;
  left: 50%;
  top: 50%;
  margin: -13px 0 0 -13px;
  padding: 0;
  display: block;
  visibility: visible;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}
.fancybox-prev {
  left: 10px;
}
.fancybox-prev span {
  background-image: url(../images/arrow-left.png);
}
.fancybox-next {
  right: 10px;
}
.fancybox-next span {
  background-image: url(../images/arrow-right.png);
}
/* ==========================================================================
   Breadcrumb
   ========================================================================== */
.breadcrumb-list {
  margin: 0;
  padding: 0;
  list-style: none;
}
/* ==========================================================================
   Pagination
   ========================================================================== */
.pagination-list {
  margin: 0;
  padding: 0;
  list-style: none;
}
/* ==========================================================================
   Social
   ========================================================================== */
.social-list {
  font-size: 0;
  line-height: 0;
}
/* ==========================================================================
   cyber
   ========================================================================== */
.cyber {
  padding: 60px 0 50px;
}
.cyber__wrapper {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.cyber__link {
  display: block;
  font-size: 0;
  line-height: 0;
  text-align: center;
}
.cyber__banner {
  display: inline-block;
  width: 100%;
  max-width: 300px;
  height: auto;
}
/* ==========================================================================
   Footer
   ========================================================================== */
.upward {
  width: 50px;
  height: 50px;
  position: relative;
  display: block;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0%);
  cursor: pointer;
  z-index: 0;
  margin-top: 20px;
}
.clear-footer {
  height: 250px;
}
.footer {
  width: 100%;
  min-height: 250px;
  margin: -250px 0px 0px 0px;
  overflow: hidden;
  background: #262626;
}
.footer-line {
  height: 100%;
  padding: 20px 0;
}
.footer-line .center {
  padding: 0 20px;
}
.footer-logo {
  font-size: 0;
  line-height: 0;
  margin-bottom: 20px;
  display: block;
}
.footer-copyright {
  color: #fff;
  font-size: 14px;
  line-height: 20px;
  font-weight: 100;
  text-align: justify;
  text-transform: uppercase;
}
