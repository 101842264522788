/* ==========================================================================
   Variables
   ========================================================================== */

/* ==========================================================================
   Helpers
   ========================================================================== */

.clear {
    margin: 0px;
    padding: 0px;
    font-size: 0px;
    line-height: 0px;
    height: 1px;
    clear: both;
    float: none;
}

.clearfix {
    *zoom: 1;

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }
}


/* ==========================================================================
   Responsive settings
   ========================================================================== */

@-ms-viewport {
    width: device-width;
    initial-scale: 1;
}
@-o-viewport {
    width: device-width;
    initial-scale: 1;
}
@viewport {
    width: device-width;
    initial-scale: 1;
}

/* ==========================================================================
   Base
   ========================================================================== */

* {
    box-sizing: border-box;
    outline: none;
}

a {
    text-decoration: none;
    color: white;
}
a:hover {}

p {
    margin: 25px 0px;
    padding: 0px;
}

hr {
    display: block;
    height: 1px;
    border: 0 none;
    border-top: 1px solid #ccc;
    margin: 0px;
    padding: 0px;
}

h1 {
    margin: 0;
    font-weight: 400;
    font-size: 38px;
    line-height: 46px;
    text-transform: uppercase;
}

input,
textarea {
    -webkit-appearance: none;
    border-radius: 0;
}

button {
    background: none;
    border: none;
    border-spacing: 0;
    border-radius: 0;
    font-size: inherit;
    font-weight: normal;
    line-height: normal;
    margin: 0;
    padding: 0;
    text-align: left;
    text-decoration: none;
    text-indent: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
}
button::-moz-focus-inner {
    border: 0;
    padding: 0;
}

/* ==========================================================================
   Icons
   ========================================================================== */

.icon {
    display: inline-block;
}
.icon-arrow-top,
.icon-arrow-bottom,
.icon-arrow-left,
.icon-arrow-right,
.icon-close,
.icon-search,
.icon-top {
    fill: none;
    stroke: #fff;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2;
}
.icon-arrow-top {
    width: 30px;
    height: 16px;
}
.icon-play {
    fill: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 30%;
    height: 30%;
    transform: translate(-50%, -50%);
}
.icon-menu {
    fill: #fff;
    width: 30px;
    height: 30px;
}
.icon-logo {
    fill: #fff;
    width: 100%;
    height: 100%;
}
.icon-logo2 {
    fill: #fff;
}
.icon-logo-ct {
    fill: #fff;
    width: 100px;
    height: 26px;
}
.icon-logo-gear {
    fill: #fff;
    width: 40px;
    height: 40px;
}
.icon-search {
    width: 25px;
    height: 25px;
}
.icon-top {
    width: 100%;
    height: 100%;
}

/* ==========================================================================
   Links
   ========================================================================== */

.link {
    background: none;
    border: none;
    border-spacing: 0;
    font-size: inherit;
    font-weight: normal;
    line-height: normal;
    margin: 0;
    padding: 0;
    text-align: left;
    text-decoration: none;
    text-indent: 0;
}

/* ==========================================================================
   Skeleton
   ========================================================================== */

html, body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
}

body {
    background-color: #262626;
    font-family: 'Kelson Sans RU', Arial, sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: #000;
    color: #fff;
}

.center {
    width: 767px;
    max-width: 767px;
    margin: 0 auto;
    position: relative;
    height: 100%;
    width: 100%;
}

.wrapper {
    min-height:100%;
}

.container {
    padding-top: 60px;
}

/* ==========================================================================
   Page property
   ========================================================================== */

.locked {
    overflow: hidden;
}

.main-page {
    .container {
        padding-top: 0;
    }
    .header {
        background: transparent;
    }
    .header-logo,
    .header-up,
    .header-title {
        display: none;
    }
}


/* ==========================================================================
   Header
   ========================================================================== */

.header {
    width: 100%;
    height: 60px;
    background: #404040;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;

    .logo {
        display: block;
        padding: 10px 15px;
        font-size: 0;
        line-height: 0;
    }
}
.header-line {
    position: relative;
    z-index: 1000;
}
.header-line.active {
    .header-logo,
    .header-up,
    .header-title {
        display: none;
    }
    .header-burger {
        background: #262626;
    }
}
.header-line .center {
    display: table;
    width: 100%;
}
.header-logo,
.header-up,
.header-burger {
    width: 70px;
    height: 60px;
    display: table-cell;
    vertical-align: top;
}
.header-logo,
.header-up {
    text-align: left;
}
.header-burger {
    text-align: right;
}
.up,
.burger {
    display: inline-block;
    font-size: 0;
    line-height: 0;
    height: 60px;
}
.up {
    padding: 20px;
}
.burger {
    padding: 15px 20px;
}
.burger-lines {
    position: relative;
    display: inline-block;
    text-decoration: none;
    height: 20px;
    width: 30px;
}
.burger-line {
    display: block;
    background: #fff;
    width: 30px;
    height: 3px;
    border-radius: 100px;
    position: absolute;
    left: 0;
    -webkit-transition: all 0.1s ease-in-out;
    -moz-transition: all 0.1s ease-in-out;
    -o-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
}
.burger-line-1 {
    top: 0;
}
.burger-line-2 {
    top: 8px;
}
.burger-line-3 {
    top: 16px;
}
.burger.active {
    .burger-line-1 {
        top: 8px;
        -moz-transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
    }
    .burger-line-2 {
        opacity: 0;
    }
    .burger-line-3 {
        top: 8px;
        -moz-transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
    }
}
.header-title {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    max-width: 50%;

    span {
        margin: 7px 5px 3px;
        max-height: 44px;
        overflow: hidden;

        font-size: 20px;
        line-height: 22px;
        text-transform: uppercase;
        font-weight: 700;

        display: block;
    }
}
.header-panel {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #262626;
    width: 100%;
    height: 100%;
    padding-top: 60px;
    overflow: auto;
    z-index: 999;
}
.header-panel.active {
    display: block;
}
.header-panel .center {
    padding: 0 20px 20px;
}
.header-search {
    margin-bottom: 20px;
}
.search {
    position: relative;

    .search-input {
        width: 100%;
        background: transparent;
        border: 3px solid #fff;
        border-radius: 5px;
        height: 50px;
        padding: 10px 60px 10px 20px;
        font-size: 16px;
        text-transform: uppercase;
    }
    .search-button {
        width: 26px;
        height: 26px;
        position: absolute;
        margin: 12px 20px;
        right: 0;
        top: 0;
    }
}
.menu {
    .menu-list {
        margin: 0;
        padding: 0;
        list-style: none;
    }
    .menu-item {
        margin-bottom: 20px;
    }
    .menu-item.active {
        .menu-arrow {
            display: block;
        }
    }
    .menu-link {
        color: #fff;
        position: relative;
        line-height: normal;

        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
    }
    .menu-link:hover {}
    .menu-icon {
        width: 40px;
    }
    .menu-arrow {
        width: 12px;
        height: 26px;
        display: none;
    }
    .menu-text {
        font-size: 24px;
        line-height: 30px;
        text-transform: uppercase;
        padding-top: 5px;
    }

    .menu-item--logo {
        height: 70px;
    }

    .menu-item--logo a {
        display: block;
        height: 100%;
        padding: 5px;
        padding-left: 40px;
    }

    .menu-item--logo img {
        height: 100%;
    }
}

/* ==========================================================================
   Common
   ========================================================================== */

.title {
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    background: #404040;
    padding: 10px;
}

.list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .item {}
    .item-header {}
    .item-image {
        display: block;
        overflow: hidden;
        position: relative;
        font-size: 0;
        line-height: 0;
    }
    img {
        max-width: 100%;
        width: 100%;
        height: auto;
    }
    .item-content {
        padding: 10px;
    }
    .item-title {
        color: #fff;
    }
}

.actions {}
.action-more {
    position: relative;
    text-align: center;
    display: block;
    width: 100%;
    padding: 10px 50px;

    .action-icon {
        position: absolute;
        left: 0;
        top: 0;
        margin: 0;
        padding: 0;
        padding: 12px 10px;
        overflow: hidden;
    }
    .arrow-more {
        width: 26px;
        height: 12px;
    }
    .action-text {
        text-transform: uppercase;
        font-size: 16px;
        font-weight: 700;
    }
}

.promo {}
.promo-text {
    padding: 20px;
    text-align: justify;
    font-size: 16px;
    font-weight: 300;
}

.news {}
.news .list,
.detail-news .list {
    .item {
        width: 50%;
        background: #404040;
    }
    .item-image {
        height: 130px;
    }
    img {
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
    .item-title {
        color: #fff;
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
        display: block;
    }
}

.air {}

.projects {}
.projects .list {
    .item {
        width: 50%;
        background: #404040;
    }
    .item-image {
        height: 130px;
    }
    img {
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
    .item-title {
        color: #fff;
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
        margin-bottom: 10px;
        display: block;
    }
    .item-text {
        font-size: 14px;
        font-weight: 400;
    }
}

.presenters {}
.presenters .list {
    .item {
        width: 33.333%;
        height: 170px;
    }
    .item-image {
        width: 100%;
        height: 100%;
        display: block;
    }
    img {
        height: 100%;
        width: 100%;
        max-width: 100%;
        object-fit: cover;
        object-position: center;
    }
    .item-title {
        display: none;
    }
}

.companions {}
.companions .list {
    font-size: 0;
    display: flex;
    flex: 0 1 auto;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 1rem 0;

    .item {
        flex: 0 0 auto;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        width: 20%;
    }
    .item-image {
        max-width: 100%;
        height: auto;
    }
}

.banners {
    margin-bottom: 20px;
}
.banners .list {
    text-align: center;

    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .item {
        display: inline-block;
        vertical-align: top;
        width: 50%;
        background: #404040;
    }

    img {
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
}

.partners {
    padding: 20px;
    font-size: 16px;
    font-weight: 100;

    ul {
        margin: 20px 0;
        padding-left: 15px;
    }
    ul li {
        text-transform: uppercase;
        font-weight: 400;
        margin-bottom: 5px;
        font-size: 14px;
    }
    a {
        color: #fff;
    }
}

.external-partners {
    margin-bottom: 20px;

    .list {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .item {
        display: inline-block;
        vertical-align: top;
        width: 20%;
        padding: 10px;
    }
    img {
        max-width: 100%;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
}

.contacts {
    padding: 20px;

    .contacts-list {}
    .contacts-item {
        margin-bottom: 40px;
    }
    .item-text {
        margin-bottom: 15px;
        font-weight: 100;
        font-size: 16px;
    }
    .item-address {
        text-transform: uppercase;
        font-weight: 700;
        margin-bottom: 2px;
    }
    a {
        color: #fff;
    }
    .item-phone {
        text-transform: uppercase;
        font-weight: 700;
    }
}

.program {
    padding-top: 70px;
}
.program-timeline {
    position: fixed;
    top: 60px;
    left: 50%;
    transform: translate(-50%,0);
    width: 100%;
    max-width: 767px;
    z-index: 100;
}
.timeline {
    background-color: #262626;
    display: flex;
    flex: 0 1 auto;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    text-align: center;
    font-size: 0;
    height: 70px;

    .date {
        width: 100%/7%;
        padding: 10px 5px;
        background: #262626;
        color: #fff;
    }
    .date.active {
        background: #404040;
    }
    .date-weekday {
        font-size: 26px;
        font-weight: 700;
        text-transform: uppercase;
    }
    .date-number {
        font-size: 11px;
    }
}
.program-list {}
.program-item {
    background: #404040;
    padding: 20px 20px 10px;
    display: table;
    width: 100%;

    .item-time,
    .item-content {
        display: table-cell;
        vertical-align: top;
    }

    .item-time {
        font-size: 24px;
        font-weight: 700;
        padding-right: 20px;
        width: 100px;
    }
    .item-title {
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 10px;
        display: block;
        color: #fff;
    }
    .item-description {
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 10px;
    }
    .item-text {
        font-weight: 100;
        font-size: 16px;
    }
}
.program-item:last-child {
    padding-bottom: 30px;
}

.search-detail {
    padding: 30px 20px 0px;
}
.search-box {
    margin-bottom: 30px;
}
.search-box:last-child {
    margin-bottom: 0;
}
.search-result {
    margin-bottom: 30px;
}
.result-list {
    .result-item {
        margin-bottom: 30px;
    }
    .item-title {
        color: #fff;
        text-transform: uppercase;
        margin-bottom: 10px;
        display: block;
        font-size: 20px;
    }
    span {
        border-left: 1px solid #fff;
        display: inline;
        vertical-align: middle;
        padding-left: 10px;
        margin-left: 10px;
    }
    .item-text {
        font-size: 18px;
        font-weight: 100;
    }
}

.detail {}
.about-video {
    position: relative;
    cursor: pointer;
}
.about-video.active {
    .about-video-icon,
    .about-video-image {
        opacity: 0;
        display: none;
        z-index: -1;
    }
    .about-video-frame {
        opacity: 1;
        position: relative;
        z-index: 1;
    }
}
.about-video-icon {
    opacity: 1;
}
.about-video-image {
    font-size: 0;
    margin: 0;
    padding: 0;
    opacity: 1;
    z-index: 1;

    iframe {
        height: 100%;
    }

    img {
        max-width: 100%;
        width: 100%;
        height: auto;
    }
}
.about-video-frame {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    min-height: 360px;

    .video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    img {
        max-width: 100%;
        height: auto;
    }
    iframe {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        border: 0;
    }
}
.about-description {
    padding: 10px;
    font-size: 12px;
    font-weight: 100;
    text-transform: uppercase;

    span {
        display: inline-block;
        border-left: 1px solid #fff;
        padding-left: 10px;
        margin-left: 10px;
    }
}
.about-post {
    .post-text {
        height: 100px;
        overflow: hidden;
        display: block;
        position: relative;
        padding: 10px;
        font-weight: 100;
        font-size: 16px;
    }
    .post-text:after {
        content: '';
        display: block;
        width: 100%;
        height: 50px;
        position: absolute;
        bottom: 0;
        left: 0;
        background: -moz-linear-gradient(top,  rgba(0,0,0,0) 0%, rgba(38,38,38,1) 100%);
        background: -webkit-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(38,38,38,1) 100%);
        background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(38,38,38,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#262626',GradientType=0 );
    }
    .post-actions {
        padding: 0 20px;
        margin: 10px 0;
        text-align: right;
    }
    .arrow-post {
        width: 20px;
        height: 10px;
        transition: all 0.3s ease-in-out;
    }
    p {
        margin-top: 0;
        margin-bottom: 10px;
    }
    p:last-child {
        margin-bottom: 0;
    }
}
.about-post.active {
    .post-text {
        height: auto;
    }
    .post-text:after {
        display: none;
    }
    .arrow-post {
        transform: rotate(-180deg);
    }
}
.detail-data {
    .data {
        padding: 20px;
        font-weight: 100;
        font-size: 16px;

        .clearfix;
    }
    .data-image {
        float: left;
        margin-right: 20px;
        margin-bottom: 10px;
        max-width: 100%;
        width: 50%;
        height: auto;
    }
    p {
        margin-top: 0;
        margin-bottom: 10px;
    }
    p:last-child {
        margin-bottom: 0;
    }
}


.media .list {
    .item {
        width: 50%;
        background: #404040;
        position: relative;
    }
    .item-image {
        height: 130px;
    }
    img {
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
    .media-play {
        width: 45%;
        height: 45%;
    }
}

.not-found {
    padding: 20px;
}
.not-found-title {
    font-size: 28px;
    font-weight: 100;
    text-align: center;
    margin-bottom: 20px;
}
.not-found-text {
    font-size: 16px;
    font-weight: 100;
}

/* ==========================================================================
   Swiper
   ========================================================================== */

.carousel {
    position: relative;
}
.carousel-container {}
.carousel-pagination {}
.carousel-logo {}
.carousel-list {}
.carousel-item {}
.carousel-button {
    background: none;
    top: 50%;
    margin-top: -13px;
    width: 12px;
    height: 26px;
    z-index: 3;
}
.carousel-button .icon {
    width: 12px;
    height: 26px;
    stroke: #fff;
}
.swiper-button-prev {
    left: 10px;
}
.swiper-button-next {
    right: 10px;
}
.swiper-pagination {
    top: 0;
    padding: 20px;
}
.swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    background: rgba(255,255,255,0.5);
}
.swiper-pagination-bullet-active {
    background: #fff;
}

.promo-carousel {
    //height: 350px;

    .item-header {
        position: relative;
        padding-bottom: 56.25%;
        margin: 0 40px;
    }
    .item-logo {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 2;
        transform: translate(-50%, -50%);
        width: 50%;
        height: 60%;
        opacity: 0.5;
    }
    .item-image {
        position: relative;
        font-size: 0;
        line-height: 0;
        height: 250px;
    }
    .item-image:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0,0,0,0.5);
    }
    img {
        max-width: 100%;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;;
    }
    .item-content {
        padding: 20px;
    }
    .item-text {
        text-align: justify;
        font-size: 16px;
        font-weight: 300;
    }
    iframe,
    video {
        border: none;
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    .carousel-pagination {
        height: auto;
        bottom: auto;
        z-index: 100;
    }
}

.gallery-carousel {
    .item-header {
        position: relative;
    }
    .item-image {
        position: relative;
        font-size: 0;
        line-height: 0;
        // height: 180px;
        overflow: hidden;
    }
    img {
        max-width: 100%;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
}

.air-carousel {
    .carousel-container {
        margin: 0 35px;
    }
    .carousel-item {
        padding: 10px 5px;
        color: #fff;
    }
    .item-time {
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 5px;
    }
    .item-title {
        font-size: 12px;
        overflow: hidden;
        position: relative;
    }
    .item-title:after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 20px;
        height: 100%;
        display: block;
        background: -moz-linear-gradient(left,  rgba(0,0,0,0) 0%, rgba(38,38,38,0.65) 100%);
        background: -webkit-linear-gradient(left,  rgba(0,0,0,0) 0%,rgba(38,38,38,0.65) 100%);
        background: linear-gradient(to right,  rgba(0,0,0,0) 0%,rgba(38,38,38,0.65) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6262626',GradientType=1 );
    }
}


/* ==========================================================================
   Fancybox
   ========================================================================== */

.fancybox-overlay {
    background-color: #404040;
    background-color: rgba(0,0,0,0.8);
    box-shadow: none;
}
.fancybox-opened .fancybox-skin {
    box-shadow: none;
    height: inherit;
}
.fancybox-wrap,
.fancybox-desktop,
.fancybox-type-image,
.fancybox-opened {
    padding: 0;
    margin: 0;
    // left: 0 !important;
    // right: 0 !important;
    // width: 100% !important;
    height: auto !important;
}

.fancybox-skin {
    border-radius: 0px;
    background: transparent;
    box-shadow: none;
}
.fancybox-inner {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    // width: 100% !important;
    height: auto !important;

    img {
        max-width: 100%;
        width: 100%;
        height: auto;
    }
    iframe {
        height: auto;
    }
}
.fancybox-close {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url(../images/close.png);
    width: 20px;
    height: 20px;
    top: -50px;
    right: 0;
    margin: 10px;
}
.fancybox-nav {
    width: 25px;
    height: 40px;
    top: 50%;
    margin-top: -20px;
}
.fancybox-nav span {
    width: 26px;
    height: 26px;
    left: 50%;
    top: 50%;
    margin: -13px 0 0 -13px;
    padding: 0;
    display: block;
    visibility: visible;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
}
.fancybox-prev {
    left: 10px;
}
.fancybox-prev span {
    background-image: url(../images/arrow-left.png);
}
.fancybox-next {
    right: 10px;
}
.fancybox-next span {
    background-image: url(../images/arrow-right.png);
}

/* ==========================================================================
   Breadcrumb
   ========================================================================== */

.breadcrumb {}
.breadcrumb-list {
    margin: 0;
    padding: 0;
    list-style: none;
}
.breadcrumb-item {}

/* ==========================================================================
   Pagination
   ========================================================================== */

.pagination {}
.pagination-list {
    margin: 0;
    padding: 0;
    list-style: none;
}
.pagination-item {}

/* ==========================================================================
   Social
   ========================================================================== */

.social {}
.social-list {
    font-size: 0;
    line-height: 0;
}
.social-item {}

/* ==========================================================================
   cyber
   ========================================================================== */

.cyber {
    padding: 60px 0 50px;
}

.cyber__wrapper {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.cyber__link {
    display: block;
    font-size: 0;
    line-height: 0;
    text-align: center;
}

.cyber__banner {
    display: inline-block;
    width: 100%;
    max-width: 300px;
    height: auto;
}

/* ==========================================================================
   Footer
   ========================================================================== */

.upward {
   width: 50px;
   height: 50px;
   position: relative;
   display: block;
   bottom: 0;
   left: 50%;
   transform: translate(-50%, 0%);
   cursor: pointer;
   z-index: 0;
   margin-top: 20px;
}

.clear-footer {
    height: 250px;
}

.footer {
    width: 100%;
    min-height: 250px;
    margin: -250px 0px 0px 0px;
    overflow: hidden;
    background: #262626;
}
.footer-line {
    height: 100%;
    padding: 20px 0;
}
.footer-line .center {
    padding: 0 20px;
}
.footer-logo {
    font-size: 0;
    line-height: 0;
    margin-bottom: 20px;
    display: block;
}
.footer-copyright {
    color: #fff;
    font-size: 14px;
    line-height: 20px;
    font-weight: 100;
    text-align: justify;
    text-transform: uppercase;
}
